@import 'styles/variables';
@import 'styles/extensions';

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
  list-style-type: none;

  @media (min-width: 2560px) {
    padding-top: calc(38px * #{$zoom});
  }

  .pagination-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    margin: auto 4px;
    padding: 0 12px;
    color: #AAA9AE;
    font-weight: normal;
    font-size: 16px;
    font-family: $secondary-font;
    line-height: 16px;
    letter-spacing: 0.01071em;
    text-align: center;
    border-bottom: 2px solid transparent;

    @media (min-width: 2560px) {
      min-width: calc(32px * #{$zoom});
      height: calc(32px * #{$zoom});
      padding: 0 calc(12px * #{$zoom});
      font-size: calc(16px * #{$zoom});
      line-height: calc(16px * #{$zoom});
      border-bottom: calc(2px * #{$zoom}) solid transparent;    
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    
    &:hover {
      border-bottom: 2px $violet solid;
      cursor: pointer;

      @media (min-width: 2560px) {
        border-bottom-width: calc(2px * #{$zoom});    
      }
    }

    &.selected {
      color: $violet;
      border-bottom: 2px $violet solid;

      @media (min-width: 2560px) {
        border-bottom-width: calc(2px * #{$zoom});    
      }
    }

    &.left-arrow,
    &.right-arrow {
      &:hover {
        border-bottom: 2px solid transparent;

        @media (min-width: 2560px) {
          border-bottom-width: calc(2px * #{$zoom});    
        }
      }
    }

    .arrow {
      &::before {
        position: relative;        
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('../../../../../../asset/resource/Arrow.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 16px;
        content: '';

        @media (min-width: 2560px) {
          width: calc(16px * #{$zoom}); 
          height: calc(16px * #{$zoom}); 
          background-size: calc(16px * #{$zoom});    
        }
      }

      &.left {
        transform: rotate(-180deg) translate(-50%);
      }

      &.right {
        transform: rotate(0);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        content: none;
      }

      &:hover {
        border-bottom: transparent;
        cursor: default;
      }
    }
  }
}