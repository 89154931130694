@import 'styles/variables';
@import 'styles/extensions';

.how-it-works {
  @extend %wrapper;
  padding-top: 100px;
  padding-bottom: 50px;
  overflow: hidden;

  @media (min-width: 2560px) {
    max-width: calc(1140px * #{$zoom});
    padding-bottom: calc(200px * #{$zoom});
    overflow: inherit;
  }

  @media screen and (max-width: 767px) {
    overflow: inherit;
  }

  h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 90px;
    padding-left: 0;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $purple;
    }
  }

  &__blocks {
    max-width: 1000px;  
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
    }
  }

  &__block {
    position: relative;
    max-width: 480px;
    margin-top: 5px;
    margin-bottom: -40px;
    padding-bottom: 38px;
    border: 4px solid $white;
    transition: margin-bottom ease-in-out .2s;

    &:nth-child(even) {
      margin-left: auto;
    }

    figure {
      display: flex;
      align-items: center;
    }

    h3 {
      color: $purple;
      font-weight: 800;
      font-size: 32px;
      font-family: $secondary-font;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
  
      @media (min-width: 2560px) {
        font-size: calc(40px * #{$zoom});
        line-height: calc(48px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    figcaption {
      padding: 0 32px; 

      @media (min-width: 2560px) {
        padding: 0 calc(32px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }

      span {
        color: $white;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;

        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
          line-height: calc(36px * #{$zoom});
        }
      }
      
      ul {
        margin: 0;
        padding-top: 20px;
        padding-left: 20px;
        list-style-type: disc;
  
        @media (min-width: 2560px) {
          padding-top: calc(30px * #{$zoom});
          padding-left: calc(20px * #{$zoom});
        }
  
        li {
          @media (min-width: 2560px) {
            font-size: calc(16px * #{$zoom});
          }
        }
      }
    }

    @media (min-width: 2560px) {
      max-width: calc(480px * #{$zoom});
      margin-top: calc(5px * #{$zoom});
      margin-bottom: calc(-40px * #{$zoom});
      padding-bottom: calc(38px * #{$zoom});
    }    

    @media screen and (max-width: 1024px) {
      margin-bottom: 25px;
    }

    &_one,
    &_three,
    &_two,
    &_four {      
      &::before {
        position: absolute;
        top: 25%;
        left: 100%;
        z-index: -1;
        width: 190px;
        height: 202px;
        background-image: url('../../../../../asset/resource/cardio-shot-image.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        content: '';
        transition: all 0.3s ease-in-out 0.2s;

        @media screen and (max-width: 1024px) {
          left: -194px;
        }
      }
    }
    
    &_two,
    &_four {
      &::before {
        left: -190px;
        transform: rotate(180deg);

        @media screen and (max-width: 1024px) {
          left: 100%;
        }
      }
    }

    &::before {
      @media screen and (max-width: 475px) {
        content: none!important;
      }
    }    

    &_one {
      @media screen and (min-width: 1024px)  {
        margin-bottom: -150px;
      }
    }

    &_three {
      margin-bottom: 0;
    }
  }

  .how-it-works__blocks > div {
    &:nth-child(2),
    &:nth-child(4) {
      .how-it-works__block {
        transform: translatex(-150vw);
        
        @media (min-width: 2560px) {
          transform: translatex(calc(-200vw * #{$zoom}))!important;
        }
      }

      .how-it-works__block-done {
        transform: translatex(0)!important;
        transition: transform 1s ease, box-shadow 0.2s ease; 
      }
    }

    &:nth-child(1) {
      .how-it-works__block {
        transform: translatex(200vw);
        
        @media (min-width: 2560px) {
          transform: translatex(calc(200vw * #{$zoom}))!important;
        }
      }

      .how-it-works__block-done {
        margin-left: auto;
        transform: translatex(0)!important;
        transition: transform 1s ease, box-shadow 0.2s ease;

        @media (min-width: 2560px) {
          transform: translatex(calc(1px * #{$zoom}))!important;
        }

        @media screen and (min-width: 1024px) and (max-width: 2559px) {
          margin-left: initial;
          transform: translatex(500px)!important;
        }
      }
    }

    &:nth-child(3) {
      .how-it-works__block {
        transform: translatex(200vw);
        
        @media (min-width: 2560px) {
          transform: translatex(calc(200vw * #{$zoom}))!important;
        }
      }

      .how-it-works__block-done {
        margin-left: auto;
        transform: translatex(0)!important;
        transition: transform 2s ease 2s, box-shadow 0.2s ease;

        @media (min-width: 2560px) {
          transform: translatex(calc(1px * #{$zoom}))!important;
        }

        @media screen and (min-width: 1024px) and (max-width: 2559px) {
          margin-left: initial;
          transform: translatex(500px)!important;
        }
      }
    }
  } 
}