@import 'styles/variables';
@import 'styles/extensions';

.header {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 96px;
  background: $black;

  &__wrapper {
    @extend %wrapper;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__event-logo {
    font-weight: 800;
    font-size: 24px;
    font-family: $secondary-font;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;

    @media (min-width: 2560px) {
      font-size: calc(24px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    &:hover {
      color: $accent;
    } 
  }

  nav {
    display: flex;
  }

  &__navigation-link {
    position: relative;

    display: flex;
    align-items: center;
    height: 96px;

    font-weight: bold;
    line-height: 0.9;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &:hover {
      color: $accent;
      cursor: pointer;
    }

    @include customLinkAnimation($accent, 4px);

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(14px * #{$zoom});
    }

    &.active {
      @include customLinkAnimation($accent, 4px);

      color: $accent;

      &::after {
        left: 0;
        width: 100%;
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 52px;
    height: 52px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: transparent;
    border-left: 1px solid transparent;
    outline: none;
    cursor: pointer;
    transition: border 0.35s;

    @media (min-width: 2560px) {
      width: calc(50px * #{$zoom});
      height: calc(50px * #{$zoom});
      margin: calc(5px * #{$zoom}) 0;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 16px;
      height: 2px;
      margin: 0 auto;
      background-color: $white;
      box-shadow: 0 -6px 0 0 $white;
      transform: translateY(-50%);
      content: '';

      @media (min-width: 2560px) {
        width: calc(16px * #{$zoom});
        height: calc(2px * #{$zoom});
      }
    }

    &::after {
      right: -8px;
      left: -4px;
      width: 12px;
      box-shadow: 0 6px 0 0 $white;

      @media (min-width: 2560px) {
        right: calc(-8px * #{$zoom});
        left: calc(-4px * #{$zoom});
        width: calc(12px * #{$zoom});
      }
    }

    &_cross {
      &::before,
      &::after {
        width: 16px;
        box-shadow: 0 0 0 0 $white;
        transition: transform 0.2s 0.1s, box-shadow 0.1s, width 0.1s, left 0s 0.1s;

        @media (min-width: 2560px) {
          width: calc(16px * #{$zoom});
        }
      }

      &::before {
        background-color: $white;
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        left: -8px;
        background-color: $white;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &__menu-navigation {
    position: fixed;
    top: 95px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(7px);
  }

  &__menu-navigation-links {
    position: absolute;
    z-index: 111;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 65vh;
    margin: 0 auto 0;
    padding: 0 40px;
    background: $black;

    &_close {
      position: absolute;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 65vh;
      margin: 0 auto 0;
      padding: 0 40px;
      background: $black;
      transform: translateY(-100%);
      transition: 0.3s all ease-in-out;
    }

    &_open {
      position: absolute;
      z-index: 111;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 50vh;
      margin: 0 auto 0;
      padding: 0 40px;
      background: $black;
      transform: translateY(0);
      transition: 0.3s all ease-in-out;
    }
  }

  &__top-navigation-link {
    position: relative;
    width: fit-content;
    font-weight: bold;

    &:hover {
      color: $accent;
      cursor: pointer;
    }

    @include customLinkAnimation($accent, 2px);
  }

  &__force-refresh {
    display: none;
    width: 80px;
    height: 80px;
    margin-right: 15px;
    margin-left: auto;
    background: transparent;
    border: none;
  }

  &.touchscreen-common {
    .header__navigation-link {
      justify-content: center;
      height: 32px;
      padding: 6px 10px;
      color: $accent;
      font-weight: 800;
      font-family: $secondary-font;
      text-align: center;
      text-transform: uppercase;
      background: transparent;
      transition: all 0.35s ease-in-out;
      
      &:hover,
      &:focus {
        color: $black;
        text-transform: uppercase;
        background: $accent;
        transition: all 0.35s ease-in-out;
      }
  
      &::after {
        content: none;
      }
  
      @media (min-width: 2560px) {
        width: calc(151px * #{$zoom});
        height: calc(32px * #{$zoom});
        padding: calc(6px * #{$zoom}) calc(16px * #{$zoom});
      }
  
      @media screen and (max-width: 1080px) {
        margin-right: 10px;
        font-size: 1.3vw;
      }
  
      &.active {
        color: $black;
        background: $accent;
        filter: brightness(100%);
        transition: background 0.35s ease-in-out;
      }
    }
  
    .header__force-refresh {
      display: block!important;
    }
  
    &__chanllenge {
      article {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
      }
  
      P {
        max-width: 728px;
  
        @media (min-width: 2560px) {
          max-width: calc(728px * #{$zoom});
        }
      }
    }
  
    &__chanllenge-qr-image {
      max-width: 168px;
  
      @media (min-width: 2560px) {
        max-width: calc(168px * #{$zoom});
      }
  
      figcaption {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -6px;
        padding: 16px 0;
        font-weight: 800;
        font-size: 20px;
        font-family: $secondary-font;
        text-transform: uppercase;
        background: linear-gradient(90deg, #00A3CF 0%, #5552A0 100%), linear-gradient(0deg, #396D98, #396D98);
  
        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
        }
      }
    }
  
    &__challenge-results {
      max-width: 1140px;
      margin-top: 50px;
      padding: 0 100px 100px;
      background: linear-gradient(90deg, rgba(0, 163, 207, 0.2) 0%, rgba(85, 82, 160, 0.2) 100%);
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        margin-top: calc(50px * #{$zoom});
        padding: 0 calc(100px * #{$zoom}) calc(100px * #{$zoom});
      }
  
      @media screen and (max-width: 1024px) {
        padding: 0 50px 70px;
      }
  
      @media screen and (max-width: 640px) {
        padding: 0 20px 70px;
      }
  
      @media screen and (max-width: 475px) {
        padding: 0 ;
        background: none;
      }
  
      .tabs__container {
        @media screen and (max-width: 1024px) {
          padding-top: 50px;
        }
      }
  
      .tabs__tab {
        font-size: 32px;
        text-transform: none;
  
        @media (min-width: 2560px) {
          font-size: calc(32px * #{$zoom});
        }
  
        &::after {
          content: none;
        }
      }
    }
  
    .empty-placeholder {
      margin-top: 60px;
  
      @media (min-width: 2560px) {
        margin-top: calc(60px * #{$zoom});
        padding-top: calc(80px * #{$zoom});
      }
  
      h3 {
        color: $white;
        font-size: 40px;
        text-transform: uppercase;
  
        @media (min-width: 2560px) {
          font-size: calc(40px * #{$zoom});
        }
      }
    }
  }
}

.header__top-navigation-link-appear {
  transform: translatex(800px);
}

.header__top-navigation-link-appear-done {
  transform: translatex(0);
  transition: transform 1s ease, box-shadow 0.2s ease;
}