@import 'styles/variables';
@import 'styles/extensions';

.success-message {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 0 15px 50px;

  &__logo-image {
    width: 100%;
    margin-top: -20px;
  }

  &__form-title {
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    text-align: center;
  }

  &__form-description {
    max-width: 85%;
    margin: 0 auto 50px;
    text-align: center;
  }

  &__qr-block {
    padding: 23px;
    border: 4px solid $accent;
    box-shadow: 0px 4px 24px rgba(255, 255, 255, 0.16);
  }

  &__qr-description {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
  }

  &__qr {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: $white;

    svg {
      width: fit-content;
    }
  }

  &__button {
    margin: 32px 0 10px!important;
  }
}