@import 'styles/variables';

.touchscreen {
  &__chanllenge {
    padding-bottom: 200px;
  }

  &__challenge-qr-image {
    display: flex;
    flex-direction: column;
    max-width: 168px;
    margin-right: 5%;
    margin-left: 15%;

    @media (min-width: 2560px) {
      max-width: calc(168px * #{$zoom});
    }

    @media (max-width: 640px) {
      margin: 0 auto;
    }

    figcaption {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: -2px;
      padding: 16px 0;
      font-weight: 800;
      font-size: 20px;
      font-family: $secondary-font;
      text-transform: uppercase;
      background: $violet;

      @media (min-width: 2560px) {
        font-size: calc(20px * #{$zoom});
      }
    }
  }

  .main__contact-us-form {
    display: none;
  }
}