@import 'styles/variables';
@import 'styles/extensions';

.challenge {
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 100px;

  @media (min-width: 2560px) {
    max-width: calc(1140px * #{$zoom});
    padding-bottom: calc(200px * #{$zoom});
  }

  @media (max-width: 767px) {
    padding-bottom: 150px;
  }

  @media (max-width: 475px) {
    padding-bottom: 100px;
  }

  h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 40px;
    padding-left: 0;
    color: $accent;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
  
    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }
  
    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }
  
    @media screen and (max-width: 450px) {
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  article {
    display: flex;
    gap: 30px;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  &__content {
    flex-basis: 60%;

    h3 {
      @extend %wrapper;
      padding-right: 0;
      padding-bottom: 35px;
      padding-left: 0;
      color: $white;
      font-weight: normal;
      font-size: 32px;
      font-family: $secondary-font;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(32px * #{$zoom});
        line-height: calc(36px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    p {
      margin-top: 30px;
      line-height: 24px;

      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }

      span {
        padding-bottom: 35px;
        color: $white;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
  
        @media (min-width: 2560px) {
          padding-bottom: calc(35px * #{$zoom});
          font-size: calc(20px * #{$zoom});
          line-height: calc(32px * #{$zoom});
        }
      }
    }
  }

  &__image {
    display: flex;
    flex-basis: 35%;
    justify-content: center;
    align-items: center;


    img {
      width: 100%;
      max-width: 286px;

      @media (min-width: 2560px) {
        max-width: calc(286px * #{$zoom});
      }
    }
  }
}

.challenge-results {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 104px 50px;
  border: 2px solid $white;

  @media (min-width: 2560px) {
    max-width: calc(1000px * #{$zoom});
  }

  @media (max-width: 768px) {
    padding: 0 35px 50px;
  }

  @media (max-width: 475px) {
    padding: 0 20px 40px;
  }
}

.empty-placeholder {
  max-width: 1000px;
  margin: 90px auto 0;

  @media (min-width: 2560px) {
    max-width: calc(1000px * #{$zoom});
    margin-top: calc(90px * #{$zoom});
    padding-top: calc(80px * #{$zoom});
  }

  h3 {
    color: $white;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(40px * #{$zoom});
    }
  }
}