@import 'styles/variables';
@import 'styles/extensions';

.main {
  width: 100%;
  background: $white;

  @media (min-width: 2560px) {
    overflow: initial;
  }

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }  

  &__wrapper {
    @extend %wrapper;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
    }
  }

  &__heading {
    position: relative;
    height: 100%;
    min-height: 1000px;

    @media (min-width: 2560px) {
      height: calc(100% * #{$zoom});
      min-height: calc(1000px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      height: 130vh;
      min-height: 1150px;
    }

    h1 {
      position: relative;
      padding-bottom: 40px;
      color: $black;
      font-weight: normal;
      font-size: 60px;
      font-family: $secondary-font;
      line-height: normal;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      span {
        color: $violet;
      }

      @media (min-width: 2560px) {
        font-size: calc(60px * #{$zoom});
        line-height: calc(60px * #{$zoom});
      }

      @media screen and (max-width: 1200px) {
        font-size: 5vw;
        line-height: 5.5vw;
      }

      @media screen and (max-width: 768px) {
        padding-bottom: calc(75vw + 80px);
        font-size: 7vw;
        line-height: 7.5vw;
      }

      @media screen and (max-width: 450px) {
        font-size: 10vw;
        line-height: 10.5vw;
      }
    }

    p {
      max-width: 700px;
      margin-bottom: 45px;
      color: $black;

      @media (min-width: 2560px) {
        max-width: calc(480px * #{$zoom});
        font-size: calc(24px * #{$zoom});
        line-height: calc(28px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        min-width: 100%;
      }

      @media screen and (max-width: 475px){
        margin-bottom: 20px;
      }
    }
  }

  &__outter-bg {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
    }
  }

  &__inner-bg {
    position: relative;  
  }

  &__heading-title {
    position: absolute;
    z-index: 1;
    max-width: 740px;   
    margin-left: 2%;      
    padding-top: 200px;

    p {
      span {
        color: $violet;
        font-weight: bold;
        font-style: italic;
      }
    }

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      max-width: 50vw;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      max-width: 100%;
      padding-top: 170px;
    }

    @media screen and (max-width: 475px) {
      margin-right: 20px;
      margin-left: 20px;  
    }
  }

  &__heading-description {
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 27px;
      line-height: 30px;
    }
  }

  &__inview {
    position: -webkit-sticky;
    position: sticky;
    top: 15%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    max-width: 560px;
    margin-left: auto;

    @media (min-width: 2560px) {
      max-width: calc(900px * #{$zoom});
    }
    
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 300px;
      left: 50%;
      justify-content: center;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 450px) {
      top: calc(300px + 10vw);
    }
  }

  &__hiro-content {
    position: relative;
    width: 640px;

    img {
      width: 100%;

      @media (min-width: 2560px) {
        position: relative;
        top: -10%;
      }

      @media screen and (max-width: 768px) {
        width: 75vw;
      }
    }

    @media (min-width: 2560px) {
      width: calc(800px * #{$zoom});
    }

    @media screen and (max-width: 1300px) {
      max-width: 500px;
    }

    @media screen and (max-width: 768px) {
      max-width: 75vw;
    }
  }

  &__section-background {
    background: $black;
  }

  &__background-top {
    position: relative;
    z-index: 3;
    background: url('../../../asset//resource/net_top_image.png'), url('../../../asset//resource/net_middle_image.png'),  linear-gradient(0.05deg, $black 1.78%, #646262 45.7%);
    background-repeat: no-repeat, no-repeat;
    background-position: 100% 0, 0 100%, 100%;
    background-size: 50%, 50%, 100%;
  }

  &__background-bottom {
    padding-bottom: 250px;
    overflow: hidden;
    background: url('../../../asset//resource/net_image.png'), linear-gradient(0.05deg, #646262 1.78%, $black 45.7%);
    background-repeat: no-repeat, no-repeat;
    background-position: 0 100%, 0 0;
    background-size: 70vw, 100%;

    @media (max-width: 475px) {
      padding-bottom: 120px;
    }
  }

  &__contact-us-form {
    padding-top: 100px;
    background: linear-gradient(0deg, $background-footer 0%, $black 51.57%);

    @media (max-width: 475px) {
      padding-top: 0;
    }
  }

  &__contact-us-form-wrapper {
    @extend %wrapper;
    display: flex;
    justify-content: space-between;
    max-width: 1140px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 345px;

    span {
      color: $accent;
    }

    p {
      margin-top: 30px;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__contact-us-form-title {
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }
}

#ekg-title {
  position: absolute;
  top: -40%;
  left: -85px;

  @media (min-width: 2560px) {
    top: -22%;
  }

  @media (max-width: 1440px) {
    left: 0;
    z-index: -1;
  }

  @media (max-width: 768px) {
    top: -8%;
  }

  @media (max-width: 475px) {
    top: -10%;
  }
}

#ekg-title .ekg-title {
  opacity: 0;
  animation: ekg-big 3s linear forwards infinite;
	fill: none;
	stroke: #7401FC;
	stroke-width: 2px;
	stroke-linecap: round;
	stroke-linejoin: miter;
	stroke-dashoffset: 620;
	stroke-dasharray: 620;
}

@keyframes ekg-big {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 1040;
  }
  99% {
    opacity: 0;
    stroke-dashoffset: 1860;
  }
  100% {
    stroke-dashoffset: 620;
  }
}