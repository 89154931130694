@import 'styles/variables';
@import 'styles/extensions';

.video-section {
  position: relative;
  overflow: hidden;
  background: $black;

  @media screen and (max-width: 767px) {
    overflow: inherit;
  }

  &__wrapper {
    @extend %wrapper;
    padding-top: 100px;
    padding-bottom: 150px;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(200px * #{$zoom});
      overflow: inherit;
    }

    @media screen and (max-width: 767px) {
      padding-top: 70px;
      padding-bottom: 100px;
    }
  }

  h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 90px;
    padding-left: 0;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $purple;
    }
  }

  &__preview {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      max-width: 665px;

      &:hover {
        opacity: 0.75;
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      display: block;
      width: 62px;
      height: 62px;
      background: url('../../../../../asset/resource/play-button.png') no-repeat 50% 50%;
      background-size: 62px 62px;
      border: 1px solid $white;
      transform: translate(-50%, -50%);
      opacity: 0.75;
      transition: opacity 0.5s;      
      content: '';
    }
  }
}