$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova', sans-serif;

$white:#FFFFFF;
$black: #000000;
$accent: #A3A0E2;
$purple: #DABAFF;
$violet: #7401FC;
$slider-gray: #414045;
$table-gray: #66666673;
$background-footer: #646262;
$footer-gray: #909095;